import Header from './header';

export default class HeaderMobile extends Header {
  constructor(...args) {
    super(...args);
    this._forceFullHeight();
    this._forceMinHeight();
  }

  // hack for ios
  _forceFullHeight() {
    if (this.dom.component.classList.contains('full-header') === false) {
      return;
    }
    this.dom.component.style.height = window.innerHeight + 'px';
  }

  // hack for ios
  _forceMinHeight() {
    if (this.dom.component.classList.contains('min-header') === false) {
      return;
    }
    this.dom.component.style.height = window.innerHeight - 120 + 'px';
  }
}
