import NavBar from './navbar';

export default class NavBarMobile extends NavBar {
  _retriveDOM() {
    super._retriveDOM();
    this.dom.backdrop = this.dom.component.querySelector('.js-menu-backdrop');
  }

  _scrollHandler(event) {
    // nothing to do
  }

  bind() {
    super.bind();
    if (this.dom.backdrop) {
      this.dom.backdrop.addEventListener('click', () => {
        this._closeMenu();
      });
    }
  }
}
