import BasketHeadline from './basketHeadline';

export default class BasketHeadlineMobile extends BasketHeadline {
  hideSidebar() {
    if (this.is_open === true) {
      this.dom.sidebar.style.display = 'none';
      this.is_open = false;
      document.body.style.overflow = 'auto';
    }
  }

  showSidebar() {
    if (this.is_open === false) {
      this.dom.sidebar.style.display = 'block';
      this.is_open = true;
      document.body.style.overflow = 'hidden';
    }
  }
}
