import Splide from '@splidejs/splide';
import CategorySlider from './category-slider';

export default class CategorySliderMobile extends CategorySlider {
  bind() {
    new Splide(this.dom.component, {
      type: 'loop',
      pagination: false,
      arrows: false,
      padding: { left: 70, right: 70 },
      gap: 20,
      focus: 'center',
    }).mount();
  }
}
