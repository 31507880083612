import { gsap } from 'gsap';
import SliderTrends from './slider-trends';
import Swipe from '../../helpers/swipe';

export default class SliderTrendsMobile extends SliderTrends {
  constructor(...args) {
    super(...args);

    this._index = 0;

    this._initDom();
    this._width = {
      slider: this.dom.slider.offsetWidth,
      pic: this.dom.pic[0].offsetWidth,
    };
    this._limit = this.dom.pic.length - 1;
    this._Swipe = new Swipe(this.dom.slider, this._process.bind(this));

    // Init for first time
    this._process('', true);
  }

  _initDom() {
    this.dom.slider = this.dom.component.querySelector('.slider');
    this.dom.pic = this.dom.slider.querySelectorAll('.pic');
  }

  _process(way, init) {
    if (way == 'right' && this._index < this._limit) this._index++;
    else if (way == 'left' && this._index > 0) this._index--;
    else if (init == true) this._index = 0;
    else return;

    const xValue = (this._width.pic - 20) * this._index;
    gsap.to(this.dom.slider, {
      duration: 0.4,
      ease: 'power3.out',
      x: -xValue,
    });
  }
}
