import navbar from '../components/navbar/navbar.mobile';
import slidertrends from '../components/slider-trends/slider-trends.mobile';
import genericslider from '../components/generic-slider/generic-slider.mobile';
import header from '../components/header/header.mobile';
import basket from '../components/basket/basket.mobile';
import basketHeadline from '../components/basket-headline/basketHeadline.mobile';
import logosSlider from '../components/logos-slider/logos-slider.mobile';
import categorySlider from '../components/category-slider/category-slider.mobile';

export default {
  navbar: navbar,
  slidertrends: slidertrends,
  'generic-slider': genericslider,
  header: header,
  basket: basket,
  basketHeadline: basketHeadline,
  'logos-slider': logosSlider,
  'category-slider': categorySlider,
};
